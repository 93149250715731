$fa-font-path: '~font-awesome/fonts';


$font-family-sans-serif: 'Lato', sans-serif !default;
$font-family-serif:      Georgia, "Times New Roman", Times, serif !default;
$font-family-monospace:  Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base:       $font-family-sans-serif !default;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

$theme-colors: (
  primary: rgba(0, 50, 80, 0.95)
);

html {
  height: 100vh; /* set viewport constraint */
  min-height: 100%; /* enforce height */
}

body {
  background-color: transparent;
  height: 100%;
}

.navbar-brand {
  font-weight: 700;
  text-transform: uppercase;
}

.nav-link,
.dropdown-item {
  font-weight: 500;
  text-transform: uppercase;
}

.my-shadow-lg {
  -webkit-box-shadow: 10px 10px 3px -6px rgba(0,0,0,0.75);
  -moz-box-shadow: 10px 10px 3px -6px rgba(0,0,0,0.75);
  box-shadow: 10px 10px 3px -6px rgba(0,0,0,0.75);
}

.fw-100 {
  font-weight: 100;
}

.fw-300 {
  font-weight: 300;
}

#log-answers-table thead th,
#logbookTable thead th,
#voucherTable thead th,
#logMeasurementTable thead th,{
  text-transform: uppercase;
}

.table th {
  border-color: #005c9d !important;
  font-size: 90%;
}

.navbar-nav .active>.nav-link {
  border-bottom: 2px solid #184dff;
}

@import "~bootstrap/scss/bootstrap";
@import "~font-awesome/scss/font-awesome";
@import "~bootstrap4-toggle/css/bootstrap4-toggle.min.css";
@import "~bootstrap-datepicker/dist/css/bootstrap-datepicker.standalone.min.css";
@import "~fine-uploader/fine-uploader/fine-uploader.min.css";
@import "~fine-uploader/fine-uploader/fine-uploader-gallery.min.css";
@import "~datatables.net-bs4/css/dataTables.bootstrap4.css";
@import "~flag-icon-css/css/flag-icon.min.css";
@import "~tempusdominus-bootstrap-4/build/css/tempusdominus-bootstrap-4.min.css";
@import "~select2/dist/css/select2.min.css";
@import "~select2-bootstrap-theme/dist/select2-bootstrap.min.css";
